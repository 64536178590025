.divider {
    width: 42%;
    border-bottom: 0.5px solid var(--light-grey2-100);
    line-height: 0.1em;
    margin: 20px 0;
}

.divider span {
    background: var(--white-100);
    padding: 0 10px;
}