.next-button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.next-button.animate {
    animation: attention-grab 1.5s infinite;
}

.next-button:hover .arrow-icon {
    animation: bounce-arrow 0.5s ease-in-out infinite;
}

.next-button:hover {
    animation: none;
}

.arrow-icon {
    display: flex;
    margin: 0.2em;
    transition: transform 0.3s ease-in-out;
}

@keyframes attention-grab {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

@keyframes bounce-arrow {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px); /* Adjust distance as needed */
    }
}