.hoverable-icon img {
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
    width: 20px;
    height: 20px;
}

.hoverable-icon:hover img {
    transform: scale(1.1); /* Scales the image to 110% when hovered */
    background: var(--base-blue-smalt-100);
}
