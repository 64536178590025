#bliKjentContainer {
    border-collapse: collapse;
    height: 100%;
    padding: 32px;
    padding-bottom: 1.2em;
    max-width: 700px;
    width: 100%;

}

.bliKjentTitle {
    width: fit-content;
}

.video-container {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    max-height: 355px;
    margin-top: 1em;
    height: 100vh;
    border-radius: 8px;
}