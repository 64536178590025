.error-image {
    max-width: 720px;
    width: 90%;
    margin: 2rem auto 0;
}

.error-msg-container {
    margin: 30px auto 40px auto;
    max-width: 800px;
    width: 80%;
    text-align: center;
}
