.dashboardDropdowns {
    display:flex;
}

.dropdownSelectionDiv {
    width: max-content;
    margin: 0px 1.5em 10px 0em;
}

.dropdownSelectionDiv span {
    display: flex;
}