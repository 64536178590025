:root {
    --beige-100: #f2f0eb;
}

html, body {
    min-height: 100%;
    overflow: auto;
}

body {
    height: 100vh;
    background-color: var(--beige-100, #f2f0eb);
    font-family: "Poppins", sans-serif !important;
    font-size: 1em !important;
}

button {
    font-family: inherit !important;
    font-size: 1em !important;
}

.App {
    text-align: center;
}
