/* colors.css */
:root {
    /* Mørk blå */
    --base-blue-cetacean-100: rgba(2, 20, 64, 1);

    /* Lys blå */
    --base-blue-smalt-100: rgba(10, 49, 154, 1);
    --base-blue-smalt-25: rgba(10, 49, 154, 0.25);
    --blue-smalt-1: rgba(196, 206, 231, 1);

    /* Grønn */
    --base-green-100: rgba(57, 221, 184, 1);
    --base-green-25: rgba(57, 221, 184, 0.25);
    --green-1: rgba(34, 137, 114, 1);

    /* Oransje */
    --base-orange-100: rgba(255, 148, 131, 1);
    --base-orange-25: rgba(255, 148, 131, 0.25);
    --orange-1: rgba(158, 92, 81, 1);

    /* Hvit */
    --base-white-100: #FEFEFE;
    --white-100: rgba(255, 255, 255, 1);
    --white-30: rgba(255, 255, 255, 0.3);
    --white-10: rgba(255, 255, 255, 0.1);
    --white-1: rgba(247, 247, 248, 1);

    /* Lys grå */
    --light-grey1-100: rgba(196, 194, 190, 1);
    --light-grey2-100: rgba(150, 149, 146, 1);
    --light-grey2-25: rgba(150, 149, 146, 0.25);
    --light-grey3-100: rgba(224, 224, 224, 1);

    /* Mørk grå */
    --dark-grey1-100: rgba(104, 103, 101, 1);
    --dark-grey2-100: rgba(58, 58, 56, 1);

    /* Svart */
    --black-100: rgba(0, 0, 0, 1);
    --black-50: rgba(0, 0, 0, 0.5);
    --black-30: rgba(0, 0, 0, 0.3);
    --black-10: rgba(0, 0, 0, 0.1);

    /* Beige */
    --beige-100: #f2f0eb;
}
