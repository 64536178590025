#title {
    text-align: left;
}

#lagNettsideCard {
    max-height: 460px;
    overflow: hidden;
}

.lag-nettside-container {
    margin: 0 auto auto 2em;
    height: 100%;
}

@media (min-width: 1520px) {
    .lag-nettside-container {
        margin: 0 auto auto 4em;
    }
}