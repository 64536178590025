.onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background: var(--beige-100), var(--beige-100);
    margin-top: -4px;
}

.onboarding-step {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
}

.onboarding-frame {
    width: 100%;
    height: 100%;
    border: none;
}

.next-button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    right: 1em;
    bottom: 1em;
    display: flex;
    align-items: center;
    gap: 5px;
}

.intro-logo {
    height: 80px;
    width: fit-content;
    position: absolute;
    top: 10px;
    left: 10px;
}

.options-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
}

.option-card {
    width: 250px;
    padding: 20px;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s ease-in-out;
}

.option-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.option-card h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #333;
}

.option-card p {
    font-size: 14px;
    color: #555;
}

@media (min-width: 1440px) {
    .next-button {
        right: 0.5em;
        bottom: 1em;
    }
}

@media (max-width: 768px) {
    .intro-logo {
        height: 50px;
        width: fit-content;
    }
}


