/* Base container styling */
.navbar-container {
    width: 208px;
    height: 100vh;
    background-color: var(--white-100);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    overflow-x: hidden;
}

.logo-section {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
}

.logo-section img {
    height: 80px;
}

.logo {
    font-size: 24px;
    font-weight: normal;
    padding-top: 28px;
}

/* Navigation item styling */
.navbar-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2em 0px 2em 5em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
}

.navbar-item-selected {
    background-color: var(--blue-smalt-1);
    color: var(--base-blue-smalt-100);
}

.navbar-item:hover:not(.navbar-item-selected) {
    color: var(--base-blue-smalt-100);
}

.navbar-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, var(--base-blue-smalt-25), var(--white-100));
    color: var(--base-blue-smalt-100);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
}

.navbar-item:hover::before {
    opacity: 1;
}

.navbar-item:hover {
    color: var(--base-blue-smalt-100);
}

.navbar-text {
    margin-left: 10px;
}

.navbar-icon {
    margin-right: 10px;
    font-size: 24px;
}

.user-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
    margin-top: auto;
}

.user-profile {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
    margin-left: 15px;
}

.initials-icon {
    background: var(--base-blue-smalt-100);
    color: var(--white-100);
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 42px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
}

.initials-icon:before {
    content: attr(data-initials);
    display: block;
}

.user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user-info-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
}

.firstName {
    white-space: nowrap;
}

.account-type {
    font-size: 12px;
    color: var(--dark-grey1-100);
}

.logout {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 30px;
}

.logout-icon {
    font-size: 30px;
    color: var(--dark-grey1-100);
    margin-right: 10px;
}

.logout-icon:hover {
    color: var(--base-blue-smalt-100);
}

.logout-text {
    color: var(--dark-grey1-100);
}
