.main-container {
    padding-left: 220px;
}

.topHalfMain {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2em;
    margin-bottom: 1em;
    margin-left: 2em;
    margin-right: auto;
}

.bottomHalfMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 2em;
    margin-right: auto;
}

.alert-google-connect {
    position: relative;
    text-align: center;
}

.alert-text {
    margin-top: 8px;
    margin-bottom: 8px;
}

.google-connect-button-div {
    margin-top: 20px;
}

.loading-quick-stats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
}

.quick-stats {
    display: flex;
    flex-direction: row;
    gap: 2.2rem;
    margin-top: 1em;
    margin-bottom: 1em;
}

.quick-stats MuiPaper-root {
    margin: 0 1em 0 1em
}

.recommend-guides-container {
    margin: 0 auto auto 2em;
    height: 100%;
}


.chart-paper {
    padding: 16px;
    margin: 16px;
    max-width: max-content;
    box-sizing: border-box;
}

.chart-container {
    margin-top: 3em !important;
}

.loading-container {
    margin: 1em;
    justify-content: center;
    align-content: center;
}

.welcome-message {
    display: flex;
    margin-top: 0.6em;
}

.traffic-chart-container {
    position: relative;
    height: 460px;
    width: 700px;
    transition: all 0.3s ease;
    margin: 0 4em 0 0;
    padding: 32px !important;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white-100);
    border-radius: 8px;
    transition: all 0.3s ease;
}

.notification-banner {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    justify-content: center;
    flex-direction: column;
    margin-top: 20%;
}

.notification-icon {
    margin-right: 10px;
    color: var(--base-blue-smalt-100);
}

#chartNoProperties {
    position: relative;
}

@media (min-width: 1520px) {
    .quick-stats {
        gap: 46px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .alert-google-connect {
        max-width: 1200px;
    }
}